import styled from 'styled-components';
import { Typography, Paper } from '@material-ui/core';
import ListItemTextBase from '@material-ui/core/ListItemText';
import ListItemBase from '@material-ui/core/ListItem';

export const Intro = styled(Typography)`
  margin-top: 50px;
`;

export const ListItem = styled(ListItemBase)`
  padding-bottom: 0;
`;

export const ListItemText = styled(ListItemTextBase)`
  font-size: 18px;
  padding: 0;
  margin-bottom: 0;
`;

export const Subtitle = styled(Typography)`
  padding: 0 16px 0 16px;
  color: inherit;
  font-weight: bold;
`;

export const Text = styled(Typography)`
  padding: 0 16px 16px 16px;
  font-size: 15px;
  color: inherit;
`;

export const BackgroundInfo = styled(Paper)`
  background: rgba(108, 202, 255, 0.3);
  padding: 12px;
  margin: 0 16px 0 16px;
`;
