import React, { FunctionComponent } from 'react';

// core components
import Footer from 'components/Footer';
import Theme from 'components/Theme';
import HeaderForm from 'components/Header/HeaderForm';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

// Images
import team from 'assets/img/izicampteam.svg';

// Styles
import { Container, Main, Title, Image, Text, Sign } from './style';

const ThankYouPage: FunctionComponent = () => {
  return (
    <Theme>
      <HeaderForm
        fixed
        changeStyleOnScroll={{
          shadowHeight: 100,
          buttonHeight: 0,
        }}
      />
      <Main>
        <Container>
          <Title>Bienvenue dans la grande famille izicamp !</Title>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Text>
                Notre équipe reviendra vers vous dans les plus brefs délais pour valider la mise en
                ligne de votre camping.
              </Text>
              <Text>
                Parce qu&apos;avec izicamp c&apos;est izi et que nous avons à coeur de vous
                satisfaire, nous restons à votre disposition au 02.40.88.36.12, 7/7j de 10h à minuit
                (si, si, puisqu&apos;on vous le dit !)
              </Text>
              <Text>Nous ne manquerons pas de vous contacter très prochainement.</Text>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Image src={team} alt="TeamLogo" />
            </GridItem>
          </GridContainer>
          <Sign>Toute l’équipe izicamp</Sign>
        </Container>
      </Main>
      <Footer />
    </Theme>
  );
};

export default ThankYouPage;
