import styled from 'styled-components';

import PageContainerBase from 'components/PageContainer';
import SubHeadingBase from '../SubHeading';

export const PageContainerModified = styled(PageContainerBase)`
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const SubHeading = styled(SubHeadingBase)`
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0px;
  min-height: 32px;
  text-decoration: none;
  padding-bottom: 20px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }
`;

export const Card = styled.div`
  text-align: center;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  min-width: 230px;
  max-width: 260px;
  margin: 0 auto;
  & h3 {
    text-transform: uppercase;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 0px;
  }
  & p {
    font-size: 1.2em;
  }
  & img {
    height: 100px;
  }
`;
