import React, { FunctionComponent } from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
// @material-ui/core components
import CardContent from '@material-ui/core/CardContent';
// Style
import { ParkApplicationValues } from 'types/parkApplication';
import {
  Card,
  Title,
  Text,
  HeaderTitle,
  CardFooter,
  CardFooterText,
  SectionTitle,
  GridItemLeft,
  GridItemRight,
  GridContainerTop,
} from './style';

interface Props {
  park: ParkApplicationValues;
}

const OwnerInfoCard: FunctionComponent<Props> = ({ park }) => {
  const fullName = `${park.firstName} ${park.lastName}`;
  const fullAddress = `${park.line1}, ${park.postcode} ${park.city}`;

  return (
    <Card>
      <HeaderTitle title={park.companyName} />
      <CardContent>
        <GridContainerTop>
          <GridItem xs={12} sm={12} md={12}>
            <Title>Adresse :</Title>
            <Text>{park.companyLine1}</Text>
            <Title>Code postal :</Title>
            <Text>{park.companyPostcode}</Text>
            <Title>Ville :</Title>
            <Text>{park.companyCity}</Text>
          </GridItem>
        </GridContainerTop>
        <GridContainer>
          <GridItemLeft xs={12} sm={6} md={6}>
            <SectionTitle>Représentée par :</SectionTitle>
            <Title>Nom et prénom du gérant de la société :</Title>
            <Text>{fullName}</Text>
            <Title>Adresse :</Title>
            <Text>{park.line1}</Text>
            <Title>Code postal :</Title>
            <Text>{park.postcode}</Text>
            <Title>Ville :</Title>
            <Text>{park.city}</Text>
            <Title>Mail :</Title>
            <Text>{park.email}</Text>
          </GridItemLeft>
          <GridItemRight xs={12} sm={6} md={6}>
            <SectionTitle>Propriétaire du camping :</SectionTitle>
            <Title>Nom :</Title>
            <Text>{park.name}</Text>
            <Title>Adresse :</Title>
            <Text>{fullAddress}</Text>
            <Title>Classement &quot;Atout France&quot; :</Title>
            <Text>{park.star} étoiles</Text>
          </GridItemRight>
        </GridContainer>
      </CardContent>
      <CardFooter>
        <CardFooterText>
          Ci-après dénomé le <b>«Propriétaire»</b>
        </CardFooterText>
      </CardFooter>
    </Card>
  );
};

export default OwnerInfoCard;
