import React, { FunctionComponent } from 'react';

// core components
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Error from 'components/Error';
import { Control, FieldErrors, FormState } from 'react-hook-form';
import { ParkApplicationValues } from 'types/parkApplication';

interface Props {
  control: Control;
  errors: FieldErrors;
  formState: FormState<ParkApplicationValues>;
}

const ParkAddressFormSection: FunctionComponent<Props> = ({ control, errors, formState }) => {
  return (
    <div>
      <h2>Adresse du camping</h2>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="line1"
            control={control}
            labelText="Adresse"
            error={errors.line1 && formState.touched.line1}
            success={!errors.line1 && formState.touched.line1}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'line1',
            }}
          />
          {errors.line1 && <Error message={errors.line1.message} />}
          <CustomInput
            name="line2"
            control={control}
            labelText="Complément d'adresse"
            error={errors.line2 && formState.touched.line2}
            success={!errors.line2 && formState.touched.line2}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'line2',
            }}
          />
          {errors.line2 && <Error message={errors.line2.message} />}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="city"
            control={control}
            labelText="Ville"
            error={errors.city && formState.touched.city}
            success={!errors.city && formState.touched.city}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'city',
            }}
          />
          {errors.city && <Error message={errors.city.message} />}
          <CustomInput
            name="postcode"
            control={control}
            labelText="Code postal"
            error={errors.postcode && formState.touched.postcode}
            success={!errors.postcode && formState.touched.postcode}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'postcode',
              maxLength: 5,
            }}
          />
          {errors.postcode && <Error message={errors.postcode.message} />}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ParkAddressFormSection;
