import React, { FunctionComponent } from 'react';

// core components
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Error from 'components/Error';
import { Control, FieldErrors, FormState } from 'react-hook-form';
import { ParkApplicationValues } from 'types/parkApplication';

interface Props {
  control: Control;
  errors: FieldErrors;
  formState: FormState<ParkApplicationValues>;
}

const CompanyAddressFormSection: FunctionComponent<Props> = ({ control, errors, formState }) => {
  return (
    <div>
      <h2>Société</h2>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="companyName"
            control={control}
            labelText="Dénomination Sociale"
            error={errors.companyName && formState.touched.companyName}
            success={!errors.companyName && formState.touched.companyName}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'companyName',
            }}
          />
          {errors.companyName && <Error message={errors.companyName.message} />}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="companyLine1"
            control={control}
            labelText="Adresse de la société"
            error={errors.companyLine1 && formState.touched.companyLine1}
            success={!errors.companyLine1 && formState.touched.companyLine1}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'companyLine1',
            }}
          />
          {errors.companyLine1 && <Error message={errors.companyLine1.message} />}
          <CustomInput
            name="companyLine2"
            control={control}
            labelText="Complément d'adresse"
            error={errors.companyLine2 && formState.touched.companyLine2}
            success={!errors.companyLine2 && formState.touched.companyLine2}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'companyLine2',
            }}
          />
          {errors.companyLine2 && <Error message={errors.companyLine2.message} />}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            name="companyCity"
            control={control}
            labelText="Ville de la société"
            error={errors.companyCity && formState.touched.companyCity}
            success={!errors.companyCity && formState.touched.companyCity}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'companyCity',
            }}
          />
          {errors.companyCity && <Error message={errors.companyCity.message} />}
          <CustomInput
            name="companyPostcode"
            control={control}
            labelText="Code postal de la société"
            error={errors.companyPostcode && formState.touched.companyPostcode}
            success={!errors.companyPostcode && formState.touched.companyPostcode}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'companyPostcode',
              maxLength: 5,
            }}
          />
          {errors.companyPostcode && <Error message={errors.companyPostcode.message} />}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CompanyAddressFormSection;
