import React, { FunctionComponent, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

// core components
import PaginationDots from 'components/PaginationDots';

interface Props {
  items: Array<any>;
  startIndex: number;
}

const Swiper: FunctionComponent<Props> = ({ items, startIndex = 0, ...rest }) => {
  const [index, setIndex] = useState(startIndex);
  const handleChangeIndex = (newIndex: number) => {
    setIndex(newIndex);
  };
  return (
    <div {...rest}>
      <SwipeableViews index={index} enableMouseEvents onChangeIndex={handleChangeIndex}>
        {items.map((item: any) => item)}
      </SwipeableViews>
      <PaginationDots dots={items.length} index={index} onChangeIndex={handleChangeIndex} />
    </div>
  );
};

export default Swiper;
