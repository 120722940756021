import React, { FunctionComponent } from 'react';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoCard from 'views/ParkApplicationSignature/Sections/SignSummarySection/Cards/InfoCard';
import OwnerInfoCard from 'views/ParkApplicationSignature/Sections/SignSummarySection/Cards/OwnerInfoCard';
import { ParkApplicationValues } from 'types/parkApplication';

// Images
import handShakeLogo from 'assets/img/headerlandingcamping.svg';
import { ImageDiv, Image, Text } from './style';

interface Props {
  park: ParkApplicationValues;
}
const SignSummarySection: FunctionComponent<Props> = ({ park }) => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Text>Entre :</Text>
          <InfoCard />
          <ImageDiv>
            <Image src={handShakeLogo} alt="Hand Shake Logo" />
          </ImageDiv>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Text>Et la société :</Text>
          <OwnerInfoCard park={park} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SignSummarySection;
