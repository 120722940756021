import React, { FunctionComponent, useState } from 'react';
// Apollo graphql
import { ApolloProvider } from '@apollo/client';
// core components
import Theme from 'components/Theme';
import HeaderForm from 'components/Header/HeaderForm';
import Footer from 'components/Footer';
import Button from 'components/CustomButtons/Button';
// Lib
import client from '../../graphql';
// Sections
import BaseFormSection from './Sections/BaseFormSection';
import SearchParkSection from './Sections/SearchParkSection';
// Styles
import { Container, Title, Main } from './style';

export type ParkOption = {
  name: string;
  parkId: string;
  chain: string;
  star: number;
  postcode: string;
  city: string;
  line1: string;
  line2: string;
};

const ParkApplicationForm: FunctionComponent = () => {
  const [park, setPark] = useState<ParkOption | undefined>(undefined);
  return (
    <Theme>
      <HeaderForm
        fixed
        changeStyleOnScroll={{
          shadowHeight: 100,
          buttonHeight: 0,
        }}
      />
      <ApolloProvider client={client}>
        <Main>
          <Container>
            <Title>Déclarez vous proprietaire de votre camping</Title>
            <SearchParkSection onChangePark={setPark} />
            {park ? (
              <BaseFormSection park={park} />
            ) : (
              <Button
                size="sm"
                color="primary"
                onClick={(): void =>
                  setPark({
                    name: '',
                    parkId: '',
                    chain: '',
                    star: 0,
                    postcode: '',
                    city: '',
                    line1: '',
                    line2: '',
                  })
                }
              >
                J{"'"}ajoute mon camping
              </Button>
            )}
          </Container>
        </Main>
        <Footer />
      </ApolloProvider>
    </Theme>
  );
};

export default ParkApplicationForm;
