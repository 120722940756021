import React, { FunctionComponent } from 'react';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';
// Mobile detect
import { isMobileOnly } from 'react-device-detect';
// Core components
import Header, { ChangeStyleOnScroll } from 'components/Header';
// Assets
import logo from 'assets/img/logo.svg';
// Styles
import { Flex, PhoneNumber, PhoneNumberIcon, Button } from './style';

interface Props {
  fixed: boolean;
  absolute?: boolean;
  changeStyleOnScroll: ChangeStyleOnScroll;
}

const HeaderLandingPage: FunctionComponent<Props> = ({
  changeStyleOnScroll,
  fixed,
  absolute = false,
}) => {
  const [isButtonDisplayed, setButtonDisplay] = React.useState(false);

  const headerStyleChange = () => {
    const windowsScrollTop = window.pageYOffset;
    setButtonDisplay(windowsScrollTop > changeStyleOnScroll.buttonHeight);
  };

  React.useEffect(() => {
    if (changeStyleOnScroll) {
      window.addEventListener('scroll', headerStyleChange);
    }
    return function cleanup() {
      if (changeStyleOnScroll) {
        window.removeEventListener('scroll', headerStyleChange);
      }
    };
  });

  return (
    <Header fixed={fixed} absolute={absolute} changeStyleOnScroll={changeStyleOnScroll}>
      <Flex>
        <a href="https://izicamp.fr">
          <img src={logo} alt="" width="150px" />
        </a>
      </Flex>
      <PhoneNumber href="tel:+33240883612">
        <PhoneNumberIcon />
        {!isMobileOnly && '02 40 88 36 12'}
      </PhoneNumber>
      {isButtonDisplayed && (
        <Link to="/candidature-camping">
          <Button color="primary" size="sm">
            Commencer
          </Button>
        </Link>
      )}
    </Header>
  );
};

export default HeaderLandingPage;
