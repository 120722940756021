import React, { FunctionComponent } from 'react';

// Assets
import gainLogo from 'assets/img/partners/gain.png';
import campezCouvertLogo from 'assets/img/partners/campez_couvert.png';
import ctoutvertLogo from 'assets/img/partners/ctoutvert.png';
import stripeLogo from 'assets/img/partners/stripe.png';
import frenchTechLogo from 'assets/img/partners/french_tech.png';
import regionPaysDeLaLoireLogo from 'assets/img/partners/region_pays_de_la_loire.png';
import fnhpaLogo from 'assets/img/partners/fnhpa.png';

// Components
import PageContainer from 'components/PageContainer';
import { Wrapper, SubHeading, LogoContainer } from './style';

const PartnersSection: FunctionComponent = () => {
  return (
    <Wrapper>
      <PageContainer>
        <SubHeading>Nos partenaires</SubHeading>
        <LogoContainer>
          <li>
            <img src={gainLogo} alt="Logo GAIN" />
          </li>
          <li>
            <img src={campezCouvertLogo} alt="Logo Campez Couvert" />
          </li>
          <li>
            <img src={ctoutvertLogo} alt="Logo www.ctoutvert.com" />
          </li>
          <li>
            <img src={stripeLogo} alt="Logo Stripe" />
          </li>
        </LogoContainer>
        <SubHeading>Nos soutiens</SubHeading>
        <LogoContainer>
          <li>
            <img src={frenchTechLogo} alt="Logo La French Tech" />
          </li>
          <li>
            <img src={regionPaysDeLaLoireLogo} alt="Logo Region pays de la Loire" />
          </li>
        </LogoContainer>
        <SubHeading>Signataire de la charte 2020-2021</SubHeading>
        <LogoContainer>
          <li>
            <img src={fnhpaLogo} alt="Logo FNHPA" />
          </li>
        </LogoContainer>
      </PageContainer>
    </Wrapper>
  );
};

export default PartnersSection;
