import React, { FunctionComponent } from 'react';

// @material-ui/core components
import { Container } from './style';

const GridContainer: FunctionComponent = ({ children, ...rest }) => {
  return (
    <Container container {...rest}>
      {children}
    </Container>
  );
};

export default GridContainer;
