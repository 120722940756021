import React, { FunctionComponent } from 'react';
// Use common components
import PageContainer from 'components/PageContainer';
// Styles
import { Container, Left, List, ListItem, Item } from './style';

interface Props {
  whiteFont?: boolean;
}

const Footer: FunctionComponent<Props> = ({ whiteFont = false }) => {
  return (
    <Container whiteFont={whiteFont}>
      <PageContainer>
        <Left>
          <List>
            <ListItem button>
              <Item href="https://izicamp.fr">Izicamp</Item>
            </ListItem>
          </List>
        </Left>
      </PageContainer>
    </Container>
  );
};

export default Footer;
