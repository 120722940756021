import React, { FunctionComponent } from 'react';
// @material-ui/core components
import PageContainer from 'components/PageContainer';
// Styles
import { AppBar, ToolBar } from './style';

export interface ChangeStyleOnScroll {
  shadowHeight: number;
  buttonHeight: number;
}

interface Props {
  fixed: boolean;
  absolute: boolean;
  changeStyleOnScroll: ChangeStyleOnScroll;
}

const HeaderLandingPage: FunctionComponent<Props> = ({
  children,
  changeStyleOnScroll,
  fixed,
  absolute,
}) => {
  const [displayShadow, setDisplayShadow] = React.useState(false);

  const headerStyleChange = () => {
    const windowsScrollTop = window.pageYOffset;
    setDisplayShadow(windowsScrollTop > changeStyleOnScroll.shadowHeight);
  };

  React.useEffect(() => {
    if (changeStyleOnScroll.shadowHeight) {
      window.addEventListener('scroll', headerStyleChange);
    }
    return function cleanup() {
      if (changeStyleOnScroll.shadowHeight) {
        window.removeEventListener('scroll', headerStyleChange);
      }
    };
  });

  return (
    <AppBar displayShadow={displayShadow} absolute={absolute} fixed={fixed}>
      <PageContainer>
        <ToolBar>{children}</ToolBar>
      </PageContainer>
    </AppBar>
  );
};

export default HeaderLandingPage;
