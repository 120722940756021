import styled from 'styled-components';
import { List as ListBase, ListItem as ListItemBase } from '@material-ui/core';

export const Container = styled.footer<{
  whiteFont: boolean;
}>`
  padding: 0.9375rem 0;
  text-align: center;
  display: flex;
  z-index: 2;
  bottom: 0;
  height: 100px;
  width: 100%;
  ${({ whiteFont }) =>
    whiteFont &&
    `
    & &:hover &:focus: {
      color: #FFFFFF,
    }
    `}
`;

export const Left = styled.div`
  float: left !important;
  display: block;
`;

export const List = styled(ListBase)`
  margin-bottom: 0px;
  padding: 0px;
  margin-top: 0px;
`;

export const ListItem = styled(ListItemBase)`
  display: inline-block;
  padding: 0px;
  width: auto;
`;

export const Item = styled.a`
  color: inherit;
  padding: 0.9375rem;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
`;
