import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { useForm, NestedValue } from 'react-hook-form';
// Apollo
import { useLazyQuery, gql } from '@apollo/client';
// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import { ParkOption } from '../../index';

import { Autocomplete } from './style';

const SEARCH_PARKS = gql`
  query searchParksByPostcode($postcode: String) {
    searchParksByPostcode(postcode: $postcode) {
      parkId
      name
      chain
      star
      postcode
      city
      line1
      line2
    }
  }
`;

type FormValues = {
  autocomplete: NestedValue<ParkOption>;
  postcode: string;
};

interface Props {
  onChangePark: (selectedPark: any) => void;
}

const SearchParkSection: FunctionComponent<Props> = ({ onChangePark }) => {
  const { control, watch } = useForm<FormValues>({
    defaultValues: { autocomplete: {}, postcode: '' },
  });
  const watchPostcode = watch('postcode', '');
  const [searchParks, { loading, data }] = useLazyQuery(SEARCH_PARKS);
  const options = data && data.searchParksByPostcode ? data.searchParksByPostcode : [];
  useEffect(() => {
    if (watchPostcode.length === 2 || watchPostcode.length === 5) {
      searchParks({ variables: { postcode: watchPostcode } });
    }
  }, [watchPostcode, searchParks]);
  return (
    <div>
      <h2>Trouvez votre camping</h2>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            name="postcode"
            control={control}
            labelText="Saisissez le code postal de votre camping"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              maxLength: 5,
              name: 'postcode',
              type: 'text',
            }}
          />
          {(options.length > 0 || loading) && (
            <Autocomplete
              loading={loading}
              id="campings-autocomplete"
              options={options}
              onChange={(_e, selectedPark): void => onChangePark(selectedPark)}
              getOptionLabel={(option: ParkOption): string => option.name}
              renderInput={(params): ReactElement => (
                <TextField
                  {...params}
                  label="Trouvez votre camping"
                  variant="outlined"
                  color="primary"
                />
              )}
            />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Alert severity="warning">
            Votre camping est peut-être déjà présent dans notre base de données. Saissisez le{' '}
            <strong>code postal</strong> pour vérifier sa présence avant de vous lancer dans l{"'"}
            ajout de votre camping.
          </Alert>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SearchParkSection;
