import styled from 'styled-components';
import SubHeadingBase from '../SubHeading';

export const Wrapper = styled.div`
  background: #f8fafc;
`;

export const SubHeading = styled(SubHeadingBase)`
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0;
  padding-top: 30px;
  min-height: 32px;
  text-decoration: none;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: center;
  }
`;

export const LogoContainer = styled.ul`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  width: 100%;
  padding-left: 0px;
  text-align: center;
  & li {
    margin: 20px 10px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      margin: 20px 30px;
    }
  }
`;
