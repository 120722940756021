import styled from 'styled-components';
import { primaryColor } from 'assets/theme/colors';

// Create AppBar components before
import PhoneIconBase from '@material-ui/icons/Phone';

export const Flex = styled.div`
  flex: 1;
`;

export const PhoneNumber = styled.a`
  align-items: center;
  display: inline-flex;
  padding-right: 5px;
  font-size: 0.98em;
  color: ${primaryColor};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-weight: bold;
    & hover {
      color: ${primaryColor};
    }
    & visited {
      color: ${primaryColor};
    }
  }
`;

export const PhoneNumberIcon = styled(PhoneIconBase)`
  width: 40px;
`;
