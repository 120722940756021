import React, { FunctionComponent } from 'react';
// Mobile detect
import { isMobileOnly } from 'react-device-detect';
// Core components
import Header, { ChangeStyleOnScroll } from 'components/Header';
// Assets
import logo from 'assets/img/logo.svg';
// Styles
import { Flex, PhoneNumber, PhoneNumberIcon } from './style';

interface Props {
  changeStyleOnScroll: ChangeStyleOnScroll;
  fixed: boolean;
  absolute?: boolean;
}

const HeaderLandingPage: FunctionComponent<Props> = ({
  changeStyleOnScroll,
  fixed,
  absolute = false,
}) => {
  return (
    <Header fixed={fixed} absolute={absolute} changeStyleOnScroll={changeStyleOnScroll}>
      <Flex>
        <a href="https://izicamp.fr">
          <img src={logo} alt="" width="150px" />
        </a>
      </Flex>
      <PhoneNumber href="tel:+33240883612">
        <PhoneNumberIcon />
        {!isMobileOnly && '02 40 88 36 12'}
      </PhoneNumber>
    </Header>
  );
};

export default HeaderLandingPage;
