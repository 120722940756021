import React, { FunctionComponent } from 'react';

// Core components
import Bubble from 'components/Bubble';

// Assets
import campsiteOwnerWondering from 'assets/img/campsiteownerwondering.svg';
import secureIncomeIcon from 'assets/img/secure_income_icon.svg';
import visibilityIcon from 'assets/img/visibility_icon.svg';
import cleanlinessIcon from 'assets/img/cleanliness_icon.svg';
import phoneOperatorIcon from 'assets/img/phone_operator_icon.svg';

// Styles
import {
  Wrapper,
  SubHeading,
  Description,
  Swiper,
  SwiperCard,
  Drawing,
  BulletPoint,
  BulletPointIcon,
} from './style';

const LandlordMobileSection: FunctionComponent = () => {
  return (
    <Wrapper>
      <SubHeading>izicamp.fr pour vos résidents</SubHeading>
      <Swiper
        startIndex={0}
        items={[
          <SwiperCard key={1}>
            <Description>
              Gérer les <strong>locations</strong> de vos <strong>résidents</strong> est devenu trop
              compliqué ?
            </Description>
            <Drawing>
              <img src={campsiteOwnerWondering} alt="" />
            </Drawing>
          </SwiperCard>,
          <SwiperCard key={2}>
            <Description>
              Quand <strong>vous</strong> louez pour eux, <strong>ils</strong> ne sont pas contents
              :
            </Description>
            <Bubble>
              <p>Vous louez d{"'"}abord vos locatifs avant de louer mon mobil-home !</p>
              <p>Ah ça, pour me réclamer mon loyer, y{"'"}a du monde !</p>
              <p>
                Je ne sais jamais si c{"'"}est loué ou pas, si j{"'"}avais su, je serais venu !
              </p>
              <p>En fin de saison : Mon mobil-home est sale et cassé !</p>
            </Bubble>
          </SwiperCard>,
          <SwiperCard key={3}>
            <Description>
              Quand <strong>ils</strong> louent par eux mêmes, <strong>vous</strong> n{"'"}êtes pas
              content :
            </Description>
            <Bubble>
              <p>Concurrence déloyale, parité tarifaire non respectée.</p>
              <p>Absence de visibilité sur les vacanciers non déclarés.</p>
              <p>Perte de temps avec des vacanciers mécontents</p>
              <p>Mise en péril de l{"'"}image de marque du camping.</p>
            </Bubble>
          </SwiperCard>,
          <SwiperCard key={4}>
            <Description>
              Réglez ces problèmes grâce à{' '}
              <strong>
                izicamp<span>.fr</span>
              </strong>{' '}
              et tout le monde sera content !
            </Description>
            <BulletPoint>
              <BulletPointIcon src={secureIncomeIcon} alt="" />
              Revenus locatifs optimisés et sécurisés.
            </BulletPoint>
            <BulletPoint>
              <BulletPointIcon src={visibilityIcon} alt="" />
              Visibilité en temps réel sur les remplissages et les revenus.
            </BulletPoint>
          </SwiperCard>,
          <SwiperCard key={5}>
            <Description>
              Réglez ces problèmes grâce à{' '}
              <strong>
                izicamp<span>.fr</span>
              </strong>{' '}
              et tout le monde sera content !
            </Description>
            <BulletPoint>
              <BulletPointIcon src={cleanlinessIcon} alt="" />
              Propreté et intégrité du mobil-home assurés.
            </BulletPoint>
            <BulletPoint>
              <BulletPointIcon src={phoneOperatorIcon} alt="" />
              Service client disponible 7j/7 de 10h à minuit.
            </BulletPoint>
          </SwiperCard>,
        ]}
      />
    </Wrapper>
  );
};

export default LandlordMobileSection;
