import React, { FunctionComponent } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Control, Controller } from 'react-hook-form';

import styles from './customInputStyle';

const useStyles = makeStyles(styles);

interface FormControlProps {
  className?: string;
  fullWidth?: boolean;
  [x: string]: any;
}

interface LabelProps {
  className?: string;
  [x: string]: any;
}

interface InputProps {
  maxLength?: number;
  type?: string;
  [x: string]: any;
}

interface Props {
  name: string;
  control: Control;
  formControlProps?: FormControlProps;
  labelText: string;
  id?: string;
  labelProps?: LabelProps;
  inputProps?: InputProps;
  error?: boolean;
  white?: boolean;
  inputRootCustomClasses?: any;
  success?: boolean;
}

const CustomInput: FunctionComponent<Props> = ({
  name,
  control,
  formControlProps,
  labelText,
  id = name,
  labelProps = {},
  inputProps = {},
  error = false,
  white,
  inputRootCustomClasses = undefined,
  success = false,
}) => {
  const classes = useStyles();

  const labelClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses: string;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={(controlledInputProps) => (
        <FormControl {...formControlProps} className={formControlClasses}>
          {labelText !== undefined && (
            <InputLabel
              className={`${classes.labelRoot} ${labelClasses}`}
              htmlFor={id}
              {...labelProps}
            >
              {labelText}
            </InputLabel>
          )}
          <Input
            classes={{
              input: inputClasses,
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            }}
            id={id}
            {...inputProps}
            value={controlledInputProps.value}
            onChange={controlledInputProps.onChange}
            inputRef={controlledInputProps.ref}
          />
        </FormControl>
      )}
    />
  );
};

export default CustomInput;
