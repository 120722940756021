import styled from 'styled-components';
import ContainerBase from 'components/PageContainer';

export const Container = styled(ContainerBase)`
  margin-top: 70px;
  background: #fff;
  padding: 15px 15px 15px 60px;
`;

export const Title = styled.h1`
  color: #3c4858;
  margin: 1.75rem 0 0.875rem;
  text-decoration: none;
  font-weight: 700;
  font-family: 'Roboto', 'Times New Roman', serif;
`;

export const Main = styled.div`
  background: #ffffff;
  position: relative;
  z-index: 3;
`;
