import styled from 'styled-components';
import ContainerBase from 'components/PageContainer';

export const Main = styled.div`
  background: #ffffff;
`;
export const Container = styled(ContainerBase)`
  margin-top: 70px;
  background: #ffffff;
  padding: 15px 15px 15px 15px;
  min-height: calc(100vh - 70px - 100px);
`;
export const Title = styled.h2`
  color: #3c4858;
  margin: 1.75rem 0 2rem;
  text-decoration: none;
  font-weight: 300;
  font-family: 'Roboto', 'Times New Roman', serif;
`;
