import React, { FunctionComponent } from 'react';

import secureIncomeIcon from 'assets/img/secure_income_icon.svg';
import optimizationIcon from 'assets/img/optimization_icon.svg';
import timeIcon from 'assets/img/time_icon.svg';
import insuranceIcon from 'assets/img/insurance_icon.svg';
import phoneOperatorIcon from 'assets/img/phone_operator_icon.svg';
import damageInsuranceIcon from 'assets/img/damage_insurance_icon.svg';

import {
  Wrapper,
  PageContainerModified,
  SubHeading,
  Description,
  List,
  BulletPointIcon,
  BulletPoint,
} from './style';

const RentalDesktopSection: FunctionComponent = () => {
  return (
    <Wrapper>
      <PageContainerModified>
        <SubHeading>izicamp.fr pour vos locatifs</SubHeading>
        <Description>
          Avec{' '}
          <strong>
            izicamp<span>.fr</span>
          </strong>
          , pas de droit d{"'"}entrée,
          <br /> pas d{"'"}abonnement, pas de surcommission.
        </Description>
        <Description>
          Pour 17% HT de commission sur les séjours vendus pour votre compte,{' '}
          <strong>
            izicamp<span>.fr</span>
          </strong>
        </Description>
        <List>
          <BulletPoint>
            <BulletPointIcon src={secureIncomeIcon} alt="" />
            <p>
              <strong>Sécurise vos revenus</strong>
              <br />
              en séquestrant les montants encaissés sur Stripe
            </p>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src={optimizationIcon} alt="" />
            <p>
              <strong>Optimise la vente de vos séjours</strong>
              <br />
              en communiquant largement pour vous
            </p>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src={timeIcon} alt="" />
            <p>
              <strong>Vous offre du temps</strong>
              <br />
              en gérant toutes vos opérations commerciales
            </p>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src={phoneOperatorIcon} alt="" />
            <p>
              <strong>Informe et fidélise les vacanciers</strong>
              <br />
              en répondant à leurs questions 7j/7 de 10h à minuit
            </p>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src={insuranceIcon} alt="" />
            <p>
              <strong>
                Assure les vacanciers
                <br />
                (et votre remplissage)
              </strong>
              <br />
              en leur offrant l{"'"}assurance annulation séjour <strong>CampezCouvert</strong>
            </p>
          </BulletPoint>
          <BulletPoint>
            <BulletPointIcon src={damageInsuranceIcon} alt="" />
            <p>
              <strong>Assure les dommages causés par les vacanciers</strong>
              <br />
              en incluant dans leur séjour une assurance dommage
            </p>
          </BulletPoint>
        </List>
        <Description>
          En résumé,{' '}
          <strong>
            izicamp<span>.fr</span>
          </strong>{' '}
          c{"'"}est :<br /> nouveau, moins cher et plus de services
        </Description>
      </PageContainerModified>
    </Wrapper>
  );
};

export default RentalDesktopSection;
