import styled from 'styled-components';
import { Typography, CardHeader, Card as CardBase } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import { primaryColor } from 'assets/theme/colors';
import GridContainer from 'components/Grid/GridContainer';

export const Card = styled(CardBase)`
  margin-bottom: 50px;
`;

export const HeaderTitle = styled(CardHeader)`
  background-color: ${primaryColor};
  color: #ffffff;
`;

export const Title = styled(Typography)`
  font-size: 15px;
  padding-left: 16px;
  padding-top: 10px;
`;

export const SectionTitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  padding-left: 16px;
`;

export const Text = styled(Typography)`
  font-size: 20px;
  padding-left: 16px;
`;

export const CardFooter = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: ${primaryColor};
  color: #ffffff;
`;

export const CardFooterText = styled(Typography)`
  font-size: 20px;
`;

export const GridContainerTop = styled(GridContainer)`
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 20px;
`;

export const GridItemLeft = styled(GridItem)`
  border-right: 1px solid #d5d5d5;
  padding-top: 30px;
`;

export const GridItemRight = styled(GridItem)`
  padding-top: 30px;
`;
