import React, { FunctionComponent } from 'react';
// Styled components theme provider
import { ThemeProvider as SCThemeProvider } from 'styled-components';
// Material UI theme provider
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

// Izicamp theme
import theme from 'assets/theme';

const Theme: FunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default Theme;
