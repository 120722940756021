import React from 'react';
import styled from 'styled-components';

// Create AppBar components before
import AppBarBase from '@material-ui/core/AppBar';
import ToolBarBase from '@material-ui/core/Toolbar';

export const ToolBar = styled(ToolBarBase)`
  padding: 0px;
  min-height: 50px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
`;

interface OverridedAppBarProps {
  fixed: boolean;
  absolute: boolean;
  displayShadow: boolean;
}

export const AppBar = styled(({ displayShadow, absolute, fixed, children, ...rest }) => (
  <AppBarBase {...rest}>{children}</AppBarBase>
))<OverridedAppBarProps>`
  display: flex;
  border: 0;
  border-radius: 3px;
  padding: 0.625rem 0;
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  transition: all 150ms ease 0s;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
  z-index: unset;
  color: #555;

  ${({ displayShadow }) =>
    displayShadow
      ? `
      box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
    `
      : `
      box-shadow: none;
    `}

  ${({ absolute }) =>
    absolute &&
    `
      position: absolute;
      z-index: 1100;
    `}

  ${({ fixed }) =>
    fixed &&
    `
      position: fixed;
      z-index: 1100;
    `}
`;
