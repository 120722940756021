import React, { FunctionComponent } from 'react';

// @material-ui/core components
import CardContent from '@material-ui/core/CardContent';

// Style
import { Card, Title, Text, HeaderTitle, Subheader, CardFooter, CardFooterText } from './style';

const InfoCard: FunctionComponent = () => (
  <Card>
    <HeaderTitle
      title="SAS BOOST LOCA MOBIL"
      subheader={<Subheader>(Nom commercial : izicamp.fr)</Subheader>}
    />
    <CardContent>
      <Title>Adresse :</Title>
      <Text>14, chemin de la virée des landes 44380 PORNICHET</Text>
      <Title>Status :</Title>
      <Text>SAS</Text>
      <Title>Capital :</Title>
      <Text>90.000,00€</Text>
      <Title>RCS :</Title>
      <Text>SAINT NAZAIRE - 810 960 112</Text>
    </CardContent>
    <CardFooter>
      <CardFooterText>
        Ci-après dénomé le <b>«Prestataire»</b>
      </CardFooterText>
    </CardFooter>
  </Card>
);

export default InfoCard;
