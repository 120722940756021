import React, { FunctionComponent } from 'react';
import { Button, Dot } from './style';

interface Props {
  index: number;
  active: boolean;
  onClick: (index: number) => void;
}

const PaginationDot: FunctionComponent<Props> = ({ index, active, onClick }) => {
  const handleClick = () => {
    onClick(index);
  };

  return (
    <Button type="button" onClick={handleClick}>
      <Dot active={active} />
    </Button>
  );
};

export default PaginationDot;
